<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-lg-5">
                <div class="text-center mb-5 mt-5">
                    <h4>Pricing</h4>
                    <p class="text-muted mb-4">Please select the package you need to facilitate your project</p>
                    <!-- 
          <ul class="nav nav-pills pricing-nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#">Monthly</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Yearly</a>
            </li>
          </ul> -->
                </div>
            </div>
        </div>
        <!-- end row -->
        <div class="row justify-content-center">
            <div class="col-md-3 col-sm-6" v-for="(data, index) in dataPaket" :key="index">
                <div class="card pricing-box">
                    <div class="card-body p-4">
                        <div class="text-center">
                            <div class="mt-3">
                                <i class="ri-edit-box-line text-primary h1"></i>
                            </div>
                            <h5 class="mt-4">{{ data.nama_paket }}</h5>

                            <div class="font-size-14 mt-4 pt-2">
                                <ul class="list-unstyled plan-features">
                                    <li>{{ data.limit_proyek }} Limit Project</li>
                                    <li>{{ data.limit_anggota }} Member</li>
                                </ul>
                            </div>

                            <div class="mt-5">
                                <h1 class="font-weight-bold mb-1">
                                    <sup class="mr-1">
                                        <small>IDR</small>
                                    </sup>{{ data.biaya }}
                                </h1>
                                <p class="text-muted">Per {{ data.durasi_tampil }}</p>
                            </div>

                            <div class="mt-5 mb-3">
                                <a href="#" class="btn btn-primary w-md" @click="buyPackage(data.id)">Get started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Layout from "../../layouts/main";
import axios from 'axios';
import Swal from "sweetalert2";
export default {
    name: 'LoginExample',
    comments: { Layout },
    data() {
        return {
            baseapi: localStorage.getItem("baseapi"),
            users: JSON.parse(localStorage.getItem('user')),
            datepickerStart: '',
            datepickerEnd: '',
            name: null,
            code: null,
            value: null,
            client: null,
            description: null,
            dataPaket: [],
            showmodal: false
        }
    },
    mounted() {
        // this.$bvModal.show('bv-modal-notification')
        this.loadData();
    },
    methods: {
        selectDateStart(val) {
            Vue.set(this, 'datepickerStart', val.toLocaleDateString());
        },
        selectDateEnd(val) {
            Vue.set(this, 'datepickerEnd', val.toLocaleDateString());
        },
        loadData() {

            axios.get(this.baseapi + 'api/paket', {
                headers: {
                    Authorization: `Bearer ${this.users.accessToken}`,
                }
            }).then(response => {
                this.dataPaket = response.data.data
                console.log(response.data)
            })
        },
        buyPackage(id) {
            const project = {
                id_user: this.users.id,
                id_paket: id
            }
            axios.post(this.baseapi + 'api/berlangganan', project, {
                headers: {
                    Authorization: `Bearer ${this.users.accessToken}`,
                }
            }).then(response => {
                console.log(response)
                Swal.fire("Good job!", "You clicked the button back to project!", "success").then(result => {
                    if (result.value) {
                        this.$router.push({ path: "project" })
                    }
                });
            }).catch((err) => {
                console.log(err)
                Swal.fire("Failed!", "Please try again!", "error");
            })
        }
    },
};
</script>